import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue.svg';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { useKpiMatrix } from '../../SIngleCompany/hooks/useKpiMatrix';
import { useCallback, useMemo } from 'react';
import { CORE_KPI_FORMAT, CoreKpiRequestFrequency } from '../../../types';
import { COMPANY_KPIS_EXCEL_HEADERS } from '../../../constants/defaultValues';
import { useCoreKpiCategories } from '../../../queries/useCoreKpiCategories';
import { useAppConfig } from '../../../queries/useAppConfig';
import ExcelJS, { Alignment } from 'exceljs';
import FileSaver from 'file-saver';

export const DownloadExcelButton = () => {
  const { colors } = useTheme();
  const { companyId } = useParams();
  const { data: company } = useCompanyById(Number(companyId));
  const { getTableData } = useKpiMatrix();
  const { data: coreKpiCategories } = useCoreKpiCategories();

  const { data: appConfig } = useAppConfig();

  const periodCols = useMemo(() => {
    if (!company) return [];
    return company.kpisData[company.kpisDataFrequency as CoreKpiRequestFrequency]?.map(
      (item) => item.period
    );
  }, [company]);

  const exportKpis = useCallback(async () => {
    if (!company || !coreKpiCategories || !appConfig?.data?.coreKpiCategoriesOrder?.length) return;
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet(`${company?.name} kpis`);

    const periodColsHeaders = periodCols?.map((periodCol) => ({
      header: periodCol,
      key: periodCol,
    }));

    const columns = [...COMPANY_KPIS_EXCEL_HEADERS, ...periodColsHeaders]?.map((col) => {
      let width = 15;

      if (col.key === 'name') {
        width = 35;
      }

      if (col.key === 'metric') {
        width = 20;
      }

      return {
        ...col,
        width,
        style: {
          alignment: {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left',
          } as Alignment,
        },
      };
    });

    worksheet.columns = columns;

    const baseKeys = COMPANY_KPIS_EXCEL_HEADERS.map((header) => header.key);
    const keys = [...baseKeys, ...periodCols];

    const kpisTabledata = getTableData(
      company.kpisDataFrequency as CoreKpiRequestFrequency,
      company.kpisData,
      coreKpiCategories,
      appConfig?.data?.coreKpiCategoriesOrder
    );

    const worksheetRows = kpisTabledata.map((item) => {
      const row = keys.map((key) => {
        if (typeof item[key] === 'object') {
          return item[key].value || item[key].value === 0 ? item[key].value : '';
        }
        return item[key] || item[key] === 0 ? item[key] : '';
      });
      return row;
    });

    worksheetRows.forEach((worksheetRow) => {
      worksheet.addRow(worksheetRow);
    });

    worksheet.eachRow((row) => {
      const formatCell = row.getCell('format');

      if (
        formatCell.value === CORE_KPI_FORMAT.NUMBER ||
        formatCell.value === CORE_KPI_FORMAT.PERCENTAGE
      ) {
        periodCols.forEach((period) => {
          const periodCell = row.getCell(period);

          periodCell.alignment = {
            wrapText: true,
            vertical: 'top',
            horizontal: 'right',
          };
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${company?.name}_kpis.xlsx`);
  }, [
    appConfig?.data?.coreKpiCategoriesOrder,
    company,
    coreKpiCategories,
    getTableData,
    periodCols,
  ]);

  return (
    <Button
      onClick={exportKpis}
      variant='text'
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Download Actual Data in Excel
      </Typography>
    </Button>
  );
};
