import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { styled, useTheme } from '@mui/material';

import { TRequest } from './RequestsTable';

import { DeadlineDaysIndicator } from './DeadlineDaysIndicator';
import { Typography } from '../../../components/Typography/Typography';
import { RequestStatusCell } from '../../../components/Table/CellRenderers/RequestStatusCell';
import { RequestProgressCell } from '../../../components/Table/CellRenderers/RequestProgressCell';
import { formatDate } from '../../../utils/formatters';
import { SingleAssessmentQuestionRow, REPORT_STATUS, SURVEY_STAGE } from '../../../types';
import { ExportButton } from '../../../components/ExportButton';
import { useExportSingleAssessment } from '../../../hooks/useExportSingleAssessment';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const columnHelper = createColumnHelper<TRequest>();

const ExportButtonWrapper = styled('div')`
  text-align: right;
`;

export const useRequestsColumns = () => {
  const { colors } = useTheme();

  const { onExportAssessment, onExportCoreKpi } = useExportSingleAssessment();

  const columns = useMemo(
    () => [
      columnHelper.accessor('surveyName', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Request
          </Typography>
        ),
        meta: {
          width: '23%',
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <RequestStatusCell status={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        meta: {
          width: '9%',
        },
        enableSorting: false,
        filterFn: (row: any, columnId, selectedFilters) => {
          const values: string[] = row.getValue(columnId);
          return selectedFilters.some((filter: string) => values.includes(filter));
        },
        enableColumnFilter: true,
      }),
      columnHelper.accessor('progress', {
        cell: (info) => <RequestProgressCell progress={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Progress
          </Typography>
        ),
        size: 180,
        meta: {
          width: '12%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('deadline', {
        cell: (info) => (
          <CellWrapper>
            <Typography variant='body' color={colors.primary[90]}>
              {formatDate(info.getValue())}
            </Typography>
            {info.row.original.status !== REPORT_STATUS.APPROVED && (
              <DeadlineDaysIndicator deadlineDateStr={info.getValue()} />
            )}
          </CellWrapper>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Deadline
          </Typography>
        ),
        meta: {
          width: '5%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('reportingPeriod', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Reporting Period
          </Typography>
        ),
        size: 180,
        meta: {
          width: '12%',
        },
      }),
      columnHelper.accessor('exportData', {
        cell: (info) => {
          return (
            <ExportButtonWrapper onClick={(e) => e.stopPropagation()}>
              <ExportButton
                onExport={() => {
                  if (info.row.original.report.survey.stage === SURVEY_STAGE.CORE_KPI) {
                    onExportCoreKpi(info.row.original.report);
                    return;
                  }
                  onExportAssessment({
                    data: info.getValue() as SingleAssessmentQuestionRow[],
                    surveyName: info.row.original.surveyName,
                    showOverrideColumn: info.row.original.hasOverride,
                    hasEvaluation: info.row.original.hasEvaluation,
                  });
                }}
              />
            </ExportButtonWrapper>
          );
        },
        header: () => null,
        meta: {
          width: '10%',
        },
      }),
    ],
    [colors.accent, colors.primary, onExportAssessment, onExportCoreKpi]
  );

  return columns;
};
