import { useCallback } from 'react';
import { Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router';
import { usePortCoPortalReports } from '../hooks/usePortCoPortalReports';
import { REPORT_STATUS } from '../types';
import { ROUTES } from '../constants/routes';
import { RequestsTable, TRequest } from './components/RequestsTable/RequestsTable';
import { BasicLayout } from './components/BasicLayout';

export const PendingRequests = () => {
  const { pendingRequests } = usePortCoPortalReports();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    (row: Row<TRequest>) => {
      const reportId = row.original.id;
      const status = row.original.status;
      if (status === REPORT_STATUS.APPROVED || status === REPORT_STATUS.IN_REVIEW) return;
      navigate(`/${ROUTES.QUESTIONNAIRES}/${reportId}/progress`);
    },
    [navigate]
  );

  return (
    <BasicLayout title='Pending Requests'>
      <RequestsTable reports={pendingRequests} onRowClick={onRowClick} />
    </BasicLayout>
  );
};
