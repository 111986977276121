import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useKpiMatrix } from '../../SIngleCompany/hooks/useKpiMatrix';
import { companyKpisDataState } from '../../../state/UIState';
import { useAtom } from 'jotai';
import { useCoreKpiCategories } from '../../../queries/useCoreKpiCategories';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { CORE_KPI_FORMAT, KpisMatrixData, SURVEY_FREQUENCY } from '../../../types';
import { getCoreKPICellValue } from '../../../utils/getCoreKpiCellValue';
import { useAppConfig } from '../../../queries/useAppConfig';
import { WarningMessage } from './WarningMessage';
import { CREATE_REQUEST_FOOTER, HEADER_HEIGHT } from '../../../constants/layoutSizes';

const Wrapper = styled('div')``;

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 124px;
  height: 30px;
  &:hover {
    background-color: #f5f8ff;
  }
`;

const HeaderCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 30px;
`;

const WarningMessageWrapper = styled('div')`
  margin-bottom: 20px;
`;

const columnHelper = createColumnHelper<KpisMatrixData>();

export const ReviewStep = () => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getTableData } = useKpiMatrix();
  const [companyKpisData] = useAtom(companyKpisDataState);
  const { data: coreKpiCategories } = useCoreKpiCategories();
  const { companyId } = useParams();
  const { data: company } = useCompanyById(Number(companyId));

  const { data: appConfig } = useAppConfig();

  const kpisFrequency = useMemo(() => {
    return company?.kpisDataFrequency || SURVEY_FREQUENCY.QUARTERLY;
  }, [company]);

  const data = useMemo(() => {
    if (
      !companyKpisData ||
      !coreKpiCategories ||
      !company ||
      !appConfig?.data?.coreKpiCategoriesOrder?.length
    )
      return [];
    return getTableData(
      kpisFrequency,
      companyKpisData,
      coreKpiCategories,
      appConfig?.data?.coreKpiCategoriesOrder
    );
  }, [
    appConfig?.data?.coreKpiCategoriesOrder,
    company,
    companyKpisData,
    coreKpiCategories,
    getTableData,
    kpisFrequency,
  ]);

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            KPI
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 0,
            backgroundColor: colors.primary[0],
          },
          thStyles: {
            position: 'sticky',
            left: 0,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('categoryName', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Category
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 140,
            backgroundColor: colors.primary[0],
          },
          thStyles: {
            position: 'sticky',
            left: 140,
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Definition
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 280,
            backgroundColor: colors.primary[0],
          },
          thStyles: {
            position: 'sticky',
            left: 280,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('metric', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Metric
          </Typography>
        ),

        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 420,
            backgroundColor: colors.primary[0],
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
          },
          thStyles: {
            position: 'sticky',
            left: 420,
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
    ];

    if (!companyKpisData || !company) return cols;

    const periodCols = companyKpisData[kpisFrequency]
      .map((item) => item.period)
      .map((period, i, arr) =>
        columnHelper.accessor(period.toString(), {
          cell: (info) => {
            return (
              <CellWrapper>
                <Typography
                  variant='numbers'
                  color={colors.primary[90]}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                      info.row.original.format === CORE_KPI_FORMAT.TEXT ? 'flex-start' : 'flex-end',
                    paddingRight: '4px',
                    fontSize: '16px',
                  }}
                >
                  {getCoreKPICellValue(info.getValue().value, true, info.row.original.format)}
                </Typography>
              </CellWrapper>
            );
          },
          header: () => (
            <HeaderCellWrapper>
              <Typography variant='overline' color={colors.primary[70]}>
                {period}
              </Typography>
            </HeaderCellWrapper>
          ),
          meta: {
            width: i === arr.length - 1 ? '100%' : '140px',
            maxWidth: i === arr.length - 1 ? 'none' : '140px',
            minWidth: '140px',
            alignHeader: 'right',
            headerWrapperWidth: '124px',
          },
          enableSorting: true,
        })
      );
    return [...cols, ...periodCols];
  }, [colors.primary, company, companyKpisData, kpisFrequency]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
        Add Following Companies
      </Typography>
      <WarningMessageWrapper>
        <WarningMessage />
      </WarningMessageWrapper>
      <Table
        table={table}
        tableStyle={{ width: 'auto', minWidth: 'auto' }}
        tdStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        thStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        height={`calc(100vh - ${HEADER_HEIGHT}px - ${CREATE_REQUEST_FOOTER}px - 144px)`}
        disableRowHover
        disableLayoutAnimation
      />
    </Wrapper>
  );
};
