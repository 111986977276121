import { useCallback } from 'react';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import {
  CORE_KPI_FORMAT,
  KPISValueResponse,
  QUESTION_TYPE,
  QuestionOption,
  QuestionTableData,
  Report,
  Response,
  SingleAssessmentQuestionRow,
} from '../types';
import { getFileName } from '../utils/getFileName';
import {
  HEADER_COLUMN_ID,
  HEADER_ROW_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../constants/defaultValues';
import { getCoreKPICellValue } from '../utils/getCoreKpiCellValue';
import { flattenTree } from '../utils/treeUtilities';

const getAnswerValue = (type: QUESTION_TYPE, answer?: Response) => {
  if (type === QUESTION_TYPE.SINGLE) {
    return (answer as QuestionOption)?.name || '';
  }

  if (type === QUESTION_TYPE.MULTI) {
    return (answer as QuestionOption[])?.map((option) => option.name).join(', ') ?? '';
  }

  return (answer as string) || '';
};

enum WORKSHEET_KEYS {
  QUESTION = 'question',
  ANSWER = 'answer',
  OVERRIDE = 'override',
  OVERRIDDEN_BY = 'overriddenBy',
  EVALUATION_SCORE = 'evaluationScore',
}

type TCol = {
  header: string;
  key: WORKSHEET_KEYS;
  width?: number;
};

type TRow = Partial<Record<WORKSHEET_KEYS, string>>;

export const useExportSingleAssessment = () => {
  const onExportAssessment = useCallback(
    async ({
      data,
      surveyName,
      showOverrideColumn,
      hasEvaluation,
    }: {
      data: SingleAssessmentQuestionRow[];
      surveyName: string;
      showOverrideColumn?: boolean;
      hasEvaluation?: boolean;
    }) => {
      const workbook = new ExcelJS.Workbook();
      workbook.created = new Date();
      workbook.properties.date1904 = true;

      const worksheet = workbook.addWorksheet(surveyName);

      const cols: TCol[] = [
        { header: 'Question', key: WORKSHEET_KEYS.QUESTION, width: 100 },
        { header: 'Answer', key: WORKSHEET_KEYS.ANSWER, width: 30 },
      ];

      if (showOverrideColumn) {
        cols.push({ header: 'Override', key: WORKSHEET_KEYS.OVERRIDE, width: 30 });
        cols.push({ header: 'Overridden By', key: WORKSHEET_KEYS.OVERRIDDEN_BY, width: 15 });
      }

      if (hasEvaluation) {
        cols.push({ header: 'Evaluation Score', key: WORKSHEET_KEYS.EVALUATION_SCORE, width: 15 });
      }

      worksheet.columns = cols;

      const filteredData = data.filter((row) => row.type !== QUESTION_TYPE.TABLE);

      filteredData.forEach((row) => {
        let worksheetRow: TRow = {
          question: row.question,
          answer: getAnswerValue(row.type, row.answer),
        };

        if (showOverrideColumn) {
          worksheetRow = {
            ...worksheetRow,
            override: getAnswerValue(row.type, row.overrideAnswer),
            overriddenBy: row.overriddenBy,
          };
        }

        if (hasEvaluation) {
          worksheetRow = {
            ...worksheetRow,
            evaluationScore:
              row.evaluationScore !== undefined && row.evaluationScore !== null
                ? String(row.evaluationScore)
                : '',
          };
        }

        worksheet.addRow(worksheetRow);
      });

      worksheet.getColumn(WORKSHEET_KEYS.QUESTION).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(WORKSHEET_KEYS.ANSWER).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };

      if (showOverrideColumn) {
        worksheet.getColumn(WORKSHEET_KEYS.OVERRIDE).alignment = {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        };
        worksheet.getColumn(WORKSHEET_KEYS.OVERRIDDEN_BY).alignment = {
          vertical: 'top',
          horizontal: 'left',
        };
      }

      if (hasEvaluation) {
        worksheet.getColumn(WORKSHEET_KEYS.EVALUATION_SCORE).alignment = {
          vertical: 'top',
          horizontal: 'left',
        };
      }

      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), `${getFileName(surveyName)}.xlsx`);
    },
    []
  );

  const onExportCoreKpi = useCallback(async (report: Report) => {
    const survey = report.survey;
    const response = report.response;

    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const sections = flattenTree(survey.sections);

    const coreKpiAssessmentData = sections.map((section) => section);

    coreKpiAssessmentData?.forEach((section) => {
      const worksheet = workbook.addWorksheet(section?.name);

      const headerColsIds = (response[section.questions[0].id] as QuestionTableData)?.columns;
      const responseRows = (response[section.questions[0].id] as QuestionTableData)?.rows;

      const headerRow = section?.questions?.[0]?.tableData?.rows?.find(
        (row) => row.id === HEADER_ROW_ID
      );

      const cols = headerColsIds?.map((headerId) => {
        let width = 10;

        if (headerId === HEADER_COLUMN_ID) {
          width = 25;
        }

        if (headerId === KPI_DEFINITION_COLUMN_ID) {
          width = 40;
        }

        if (headerId === KPI_FORMAT_COLUMN_ID) {
          width = 15;
        }
        return {
          header: headerRow?.[headerId] as string,
          key: headerId,
          width,
        };
      });

      worksheet.columns = cols;

      const rows = responseRows
        ?.filter((row) => row.id !== HEADER_ROW_ID)
        ?.map((row) => {
          const kpiValue = getCoreKPICellValue(
            (row?.[KPI_VALUE_COLUMN_ID] as KPISValueResponse)?.value,
            true,
            row?.[KPI_FORMAT_COLUMN_ID] as CORE_KPI_FORMAT
          );
          return {
            [HEADER_COLUMN_ID]: row?.[HEADER_COLUMN_ID],
            [KPI_DEFINITION_COLUMN_ID]: row?.[KPI_DEFINITION_COLUMN_ID],
            [KPI_FORMAT_COLUMN_ID]: row?.[KPI_FORMAT_COLUMN_ID],
            [KPI_METRIC_COLUMN_ID]: row?.[KPI_METRIC_COLUMN_ID],
            [KPI_VALUE_COLUMN_ID]: kpiValue,
          };
        });

      rows.forEach((row) => {
        worksheet.addRow(row);
      });

      worksheet.getColumn(HEADER_COLUMN_ID).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_DEFINITION_COLUMN_ID).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_FORMAT_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_METRIC_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_VALUE_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };

      worksheet.eachRow((row) => {
        const formatCell = row.getCell(KPI_FORMAT_COLUMN_ID);

        if (
          formatCell.value === CORE_KPI_FORMAT.NUMBER ||
          formatCell.value === CORE_KPI_FORMAT.PERCENTAGE
        ) {
          row.getCell(KPI_VALUE_COLUMN_ID).alignment = {
            vertical: 'top',
            horizontal: 'right',
          };
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${getFileName(survey.name)}.xlsx`);
  }, []);

  return { onExportAssessment, onExportCoreKpi };
};
