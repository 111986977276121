import { BasicLayout } from './components/BasicLayout';
import { RequestsTable } from './components/RequestsTable/RequestsTable';
import { usePortCoPortalReports } from '../hooks/usePortCoPortalReports';

export const ApprovedRequests = () => {
  const { approvedRequests } = usePortCoPortalReports();

  return (
    <BasicLayout title='Accepted Requests'>
      <RequestsTable reports={approvedRequests} />
    </BasicLayout>
  );
};
