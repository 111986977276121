import { Button, useTheme } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { Typography } from './Typography/Typography';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-blue-small.svg';

// const Wrapper = styled('div')`
//   position: relative;
// `;

interface Props {
  onExport: () => void;
}

export const ExportButton: FC<Props> = ({ onExport }) => {
  const { colors } = useTheme();

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onExport();
    },
    [onExport]
  );

  return (
    <Button
      variant={'text'}
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
      onClick={onClick}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Export Report
      </Typography>
    </Button>
  );
};
