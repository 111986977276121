import { ReactComponent as RequestsIcon } from '../assets/icons/sidebarMenuIcons/requests.svg';
import { ReactComponent as RequestsActiveIcon } from '../assets/icons/sidebarMenuIcons/requests-active.svg';
import { ReactComponent as CompanyPerformanceIcon } from '../assets/icons/sidebarMenuIcons/company-performance.svg';
import { ReactComponent as CompanyPerformanceActiveIcon } from '../assets/icons/sidebarMenuIcons/company-performance-active.svg';
import { TMenuItem } from '../types';
import { ROUTES } from '../constants/routes';
import { usePortCoPortalReports } from './usePortCoPortalReports';

export const useMenuItems = (companyId: number) => {
  const { pendingRequests } = usePortCoPortalReports();

  if (!companyId) return [];

  const menuItems: TMenuItem[] = [
    {
      title: 'Requests',
      icon: <RequestsIcon />,
      iconActive: <RequestsActiveIcon />,
      id: 'requests',
      subItems: [
        {
          title: 'Pending Requests',
          route: `${ROUTES.PENDING_REQUESTS}`,
          id: ROUTES.PENDING_REQUESTS,
          badgeNum: pendingRequests?.length,
        },
        {
          title: 'Accepted Requests',
          route: `${ROUTES.APPROVED_REQUESTS}`,
          id: ROUTES.APPROVED_REQUESTS,
        },
      ],
    },
    {
      title: 'Company’s Performance',
      icon: <CompanyPerformanceIcon />,
      iconActive: <CompanyPerformanceActiveIcon />,
      route: `${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_PERFORMANCE}`,
      id: ROUTES.COMPANIES,
    },
  ];
  return menuItems;
};
