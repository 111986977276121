import { useReportsByCompanyId } from '../queries/useReports';
import { REPORT_STATUS, User } from '../types';
import { useAtomValue } from 'jotai';
import { userState } from '../state/UIState';
import { useMemo } from 'react';

export const usePortCoPortalReports = () => {
  const user: User = useAtomValue(userState) as User;
  const { data: reports } = useReportsByCompanyId(user?.company?.id);

  const pendingRequests = useMemo(() => {
    return (
      reports?.filter(
        (report) =>
          report?.status !== REPORT_STATUS.APPROVED && report?.status !== REPORT_STATUS.SUBMITTED
      ) || []
    );
  }, [reports]);

  const approvedRequests = useMemo(() => {
    return reports?.filter((report) => report?.status === REPORT_STATUS.APPROVED) || [];
  }, [reports]);

  return { allReports: reports, pendingRequests, approvedRequests };
};
