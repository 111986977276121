import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-file.svg';
import { QuestionTableData } from '../../../../types';
import { FC, useCallback } from 'react';
import {
  HEADER_COLUMN_ID,
  HEADER_ROW_ID,
  KPI_DEFINITION_COLUMN_ID,
} from '../../../../constants/defaultValues';
import ExcelJS, { Alignment } from 'exceljs';
import FileSaver from 'file-saver';

interface Props {
  tableData: QuestionTableData;
}

export const DownloadExcelLink: FC<Props> = ({ tableData }) => {
  const { colors } = useTheme();

  const onExportTemplate = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const { rows, columns } = tableData;

    const worksheet = workbook.addWorksheet('Core KPIs');

    const headerRow = rows.find((row) => row.id === HEADER_ROW_ID);

    const worksheetCols = columns?.map((col) => {
      let width = 15;

      if (col === HEADER_COLUMN_ID) {
        width = 30;
      }

      if (col === 'category') {
        width = 20;
      }

      if (col === KPI_DEFINITION_COLUMN_ID) {
        width = 40;
      }

      return {
        key: col,
        header: headerRow?.[col] as string,
        width,
        style: {
          alignment: {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left',
          } as Alignment,
        },
      };
    });

    worksheet.columns = worksheetCols;

    const worksheetRows = rows
      ?.filter((row) => row.id !== HEADER_ROW_ID)
      ?.map((row) => {
        let exportRows = {};

        columns?.forEach((col) => {
          exportRows = {
            ...exportRows,
            [col]: row[col] || '',
          };
        });

        return exportRows;
      });

    worksheetRows?.forEach((worksheetRow) => {
      worksheet.addRow(worksheetRow);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `Core-KPIs-Template.xlsx`);
  }, [tableData]);

  return (
    <Button
      onClick={onExportTemplate}
      variant='text'
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Download as Excel Template
      </Typography>
    </Button>
  );
};
