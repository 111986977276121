import { useReportsByCompanyId } from '../queries/useReports';
import { User } from '../types';
import { useAtomValue } from 'jotai';
import { userState } from '../state/UIState';

export const usePortCoPortalReports = () => {
  const user: User = useAtomValue(userState) as User;
  const { data } = useReportsByCompanyId(user?.company?.id);

  return { data };
};
